<template>
    <div id="mbkm_detail">
        <div class="mcontainer">
            <div class="flex justify-between relative md:mb-4 mb-3">
                <div class="flex-1">
                    <h2 class="text-lg font-semibold">Friends</h2>
                    <p>Daftar teman anda</p>
                </div>
                <div class="flex align-items-center">
                    <router-link :to="{ name: 'AccountFriends' }" class="flex mr-5 mb-0 align-items-center">
                        <span class="d-inline mr-2">
                            <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3 3.3335C3 4.9875 4.346 6.3335 6 6.3335C7.654 6.3335 9 4.9875 9 3.3335C9 1.6795 7.654 0.333496 6 0.333496C4.346 0.333496 3 1.6795 3 3.3335ZM11.3333 13.0002H12V12.3335C12 9.76083 9.906 7.66683 7.33333 7.66683H4.66667C2.09333 7.66683 0 9.76083 0 12.3335V13.0002H11.3333Z" fill="currentColor"/>
                            </svg>
                        </span>
                        <span>
                            {{ datauser.friends.length }} Friends
                        </span>
                    </router-link>

                    <router-link :to="{ name: 'AccountFriendsUnconfirmed' }" class="flex mr-5 mb-0 align-items-center">
                        <span class="d-inline mr-2">
                            <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3 3.3335C3 4.9875 4.346 6.3335 6 6.3335C7.654 6.3335 9 4.9875 9 3.3335C9 1.6795 7.654 0.333496 6 0.333496C4.346 0.333496 3 1.6795 3 3.3335ZM11.3333 13.0002H12V12.3335C12 9.76083 9.906 7.66683 7.33333 7.66683H4.66667C2.09333 7.66683 0 9.76083 0 12.3335V13.0002H11.3333Z" fill="currentColor"/>
                            </svg>
                        </span>
                        <span>
                            {{ datauser.friends_waiting_confirmation.length }} Waiting Confirmation
                        </span>
                    </router-link>
                </div>
            </div>
            <div class="mt-5">
                <div class="row">
                    <div class="col-md-12">
                        <div>
                            <div v-if="datauser.friends_waiting_confirmation.length > 0" class="row equal-cols">
                                <div class="col-md-2 col-sm-3" v-for="item in datauser.friends_waiting_confirmation" :key="item.id">
                                    <div class="card mb-4 pt-3 pb-1 overflow-visible">
                                        <div class="p-3 flex justify-content-between flex-column h-full">
                                            <div class="text-center">
                                                <img :src="item.images ? item.images : require('@/assets/images/avatars/avatar-5.jpg')" class="w-10 h-10 rounded-full mb-2 mx-auto">
                                                <router-link :to="{ name: 'Profile', params: {code: item.username } }" >
                                                    <h3 class="mt-1"><strong>{{ item.fullname }}</strong></h3>
                                                </router-link>
                                            </div>
                                            <div v-if="item.pivot.user_id !== datauser.id" class="flex justify-content-between mt-3">
                                                <a href="" @click.prevent="confirmFriend(item)" class="">
                                                    <span class="badge badge-primary small">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                                    </span>
                                                </a>
                                                <a href="" @click.prevent="rejectFiend(item)">
                                                    <span class="badge badge-danger small">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                                                    </span>
                                                </a>
                                            </div>
                                            <div v-else class="mt-3 text-center">
                                                <span class="badge badge-primary small">Waiting</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="mt-5">
                                <empty image="/images/confirmation.svg" text="Anda belum memiliki teman" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal v-model="accountitem.form.show" :title="accountitem.form.title" ok-title="Submit" :hide-footer="true">
            <form @submit.prevent="">
                <b-form-group>
                    <div class="image-uploader mb-3">
                        <input type="file" ref="images" @change="onFileSelected($event, 'images')">
                        <span @click="$refs.images.click()"><Icon icon="bi:cloud-upload" /></span>
                        <img :src="images ? images : $placeholder2" alt="Images" class="w-20">
                    </div>
                </b-form-group>
                <b-form-group>
                    <b-form-input
                        v-model="accountitem.form.data.title"
                        :placeholder="type === 'Education' ? 'Add a Institution' : type === 'Certification' ? 'Add a License' : type === 'Experience' ? 'Add a Position' : 'Add a Title'"
                    ></b-form-input>
                </b-form-group>
                <b-form-group v-if="type !== 'Skills'">
                    <b-form-input
                        v-model="accountitem.form.data.subtitle"
                        :placeholder="type === 'Education'? 'Add a Major' : type === 'Certification' || type === 'Experience' ? 'Add a Company' : 'Add a Subtitle'"
                    ></b-form-input>
                </b-form-group>
                <b-form-group>
                    <div class="row">
                        <div class="col-md-6" v-if="type !== 'Skills' && type !== 'Achievement'">
                            <datepicker v-model="accountitem.form.data.start_date" :bootstrap-styling="true" placeholder="Start Date" class="custom-input"></datepicker>
                        </div>
                        <div class="col-md-6" v-if="type !== 'Skills' && type !== 'Certification' && type !== 'Achievement'">
                            <datepicker v-model="accountitem.form.data.end_date" :bootstrap-styling="true" placeholder="End Date" class="custom-input"></datepicker>
                            <small class="text-form-control" v-if="type === 'Experience' || type === 'Education'">Leave it blank if it's still active</small>
                        </div>
                    </div>
                </b-form-group>
                <b-form-group>
                    <b-form-textarea
                        v-model="accountitem.form.data.description"
                        placeholder="Add a Description"
                        rows="3"
                    ></b-form-textarea>
                </b-form-group>
                <b-form-group align="right">
                    <div>
                        <b-button v-if="accountitem.form.data.id" variant="danger" class="mr-2" @click="deleteItem">
                            Delete
                        </b-button>
                        <b-button variant="primary" type="submit" @click="submitItem" size="sm">
                            Submit
                        </b-button>
                    </div>
                </b-form-group>
            </form>
        </b-modal>
        <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :preview-modal="true"
            :paginate-elements-by-height="1400"
            :filename="'Curiculum Vitae - ' + datauser.fullname"
            :pdf-quality="2"
            :manual-pagination="true"
            pdf-format="a4"
            pdf-orientation="portrait"
            pdf-content-width="800px"
            :margin="0"
            @progress="onProgress($event)"
            @hasStartedGeneration="hasStartedGeneration()"
            @hasGenerated="hasGenerated($event)"
            ref="html2Pdf"
        >
            <section slot="pdf-content">
                <CV :user="datauser" :items="dataItemUser" />
            </section>
        </vue-html2pdf>
    </div>
</template>
<script>
    import { Icon } from '@iconify/vue2'
    import CV from '@/components/CuriculumVitae'
    import { mapState, mapActions } from 'vuex'
    import Datepicker from 'vuejs-datepicker'
    import VueHtml2pdf from 'vue-html2pdf'
    import Empty from '@/components/Empty'

    export default {
        name: 'Account',
        computed: {
            ...mapState('auth', { datauser: 'user' }),
            ...mapState('accountItem', {
                dataItem: state => state.data
            })
        },
        components: {
            Empty,
            Icon,
            Datepicker,
            VueHtml2pdf,
            CV
        },
        data () {
            return {
                accountitem: {
                    form: {
                        title: '',
                        show: false,
                        loading: false,
                        data: {
                            id: '',
                            type: '',
                            title: '',
                            subtitle: '',
                            description: '',
                            start_date: '',
                            end_date: '',
                            images: ''
                        }
                    }
                },
                type: '',
                images: '',
                dataItemUser: {
                    skills: [],
                    achievement: [],
                    certification: [],
                    education: [],
                    experience: []
                }
            }
        },
        methods: {
            ...mapActions('auth', {
                _removeFriend: 'removeFriend',
                _confirmFriend: 'confirmFriend'
            }),
            ...mapActions('accountItem', {
                _createItem: 'create',
                _updateItem: 'update',
                _deleteItem: 'delete',
                _fetchItem: 'fetch'
            }),
            confirmFriend (friend) {
                this.friendLoading = true
                this._confirmFriend(friend.id)
                    .then(res => {
                        this.$store.dispatch('auth/getUser')
                        this.$swal(
                            'Confirmed!',
                            'Request teman dari ' + friend.fullname + ' telah diterima.',
                            'success'
                        )
                    })
                    .finally(() => {
                        this.friendLoading = false
                    })
            },
            rejectFiend (friend) {
                this.friendLoading = true
                this._removeFriend(friend.id)
                    .then(res => {
                        this.$store.dispatch('auth/getUser')
                        this.$swal(
                            'Removed!',
                            'Request teman dari ' + friend.fullname + ' telah dihapus.',
                            'success'
                        )
                    })
                    .finally(() => {
                        this.friendLoading = false
                    })
            },
            submitItem () {
                this.accountitem.form.loading = true
                const { id, type, title, subtitle, description, images } = this.accountitem.form.data
                const startDate = this.formatDateStore(this.accountitem.form.data.start_date)
                const endDate = this.formatDateStore(this.accountitem.form.data.end_date)
                const promise = id ? this._updateItem({ id, data: { type, title, subtitle, description, images, start_date: startDate, end_date: endDate } }) : this._createItem({ type, title, subtitle, description, images, start_date: startDate, end_date: endDate })
                promise.then(() => {
                    this._fetchItem(this.datauser.username).then((result) => {
                        this.dataItemUser = result
                    })
                    this.accountitem.form.show = false
                })
                    .finally(() => {
                        this.accountitem.form.loading = false
                    })
            },
            addItem (type) {
                this.accountitem.form.title = 'Add ' + type
                this.accountitem.form.show = true
                this.accountitem.form.data.id = ''
                this.accountitem.form.data.type = type
                this.accountitem.form.data.title = ''
                this.accountitem.form.data.subtitle = ''
                this.accountitem.form.data.description = ''
                this.accountitem.form.data.images = ''
                this.accountitem.form.data.start_date = ''
                this.accountitem.form.data.end_date = ''
                this.images = ''
                this.type = type
            },
            editItem (item, type) {
                this.accountitem.form.title = 'Edit ' + type
                this.accountitem.form.show = true
                this.accountitem.form.data = { ...item }
                this.images = item.images
                this.type = type
            },
            deleteItem (id) {
                this.$swal({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.accountitem.form.loading = true
                        this._deleteItem(id)
                            .then(() => {
                                this._fetchItem(this.datauser.username).then((result) => {
                                    this.dataItemUser = result
                                })
                                this.accountitem.form.show = false
                                this.$swal(
                                    'Deleted!',
                                    'Your item has been deleted.',
                                    'success'
                                )
                            })
                            .finally(() => {
                                this.accountitem.form.loading = false
                            })
                    }
                })
            },
            onFileSelected (e, target) {
                this.accountitem.form.data[target] = e.target.files[0]
                this[target] = URL.createObjectURL(this.accountitem.form.data[target])
            },
            generateCV () {
                this.$refs.html2Pdf.generatePdf()
            },
            onProgress (event) {
                if (event === 100) {}
            }
        },
        created () {
            this.$store.dispatch('auth/getUser')
                .catch(() => {
                    localStorage.removeItem('token')
                    if (this.layout === 'layout-app') this.$route.push('/')
                })
            this._fetchItem(this.datauser.username).then((result) => {
                this.dataItemUser = result
            })
        }
    }
</script>
