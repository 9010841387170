<template>
    <div class="text-center flex justify-center align-content-center flex-column">
        <img :src="image" class="w-50 mx-auto mb-2" style="max-width: 400px;">
        <p v-if="text">{{ text }}</p>
        <div v-else>
            <slot />
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            image: {
                type: String,
                default: '/images/empty.svg'
            },
            text: {
                type: String,
                default: ''
            }
        }
    }
</script>
